export enum JobFilterId {
  AREA = 'area',
  LOCATION = 'location',
  EMPLOYMENT_TYPE = 'employmentType',
  WORK_TYPE = 'workType'
}

export enum AreaKey {
  ALL = 'all',
  ART = 'art',
  DATA = 'data',
  LEGAL = 'legal',
  GAME = 'game',
  MARKETING = 'marketing',
  PEOPLE = 'people',
  PRODUCT = 'product',
  SALES = 'sales',
  TECHNOLOGY = 'technology',
  VIDEO = 'video'
}

export enum AreaLabel {
  ALL = 'All fields',
  ART = 'Art & Animation',
  DATA = 'Data & Research',
  LEGAL = 'Finance & Legal',
  GAME = 'Game & UX Design',
  MARKETING = 'Marketing & Publishing',
  PEOPLE = 'People Teams',
  PRODUCT = 'Product & Production',
  SALES = 'Sales & Business Development',
  TECHNOLOGY = 'Technology & Development',
  VIDEO = 'Video Production'
}

export enum LocationKey {
  ALL = 'all',
  LJUBLJANA = 'ljubljana',
  LIMASSOL = 'limassol',
  SHANGHAI = 'shanghai'
}

export enum LocationLabel {
  ALL = 'All locations',
  LJUBLJANA = 'Ljubljana, Slovenia',
  LIMASSOL = 'Limassol, Cyprus',
  SHANGHAI = 'Shanghai, China'
}

export enum Employer {
  EKIPA2 = 'Ekipa2 d.o.o',
  O7_LTD = 'Outfit7 Limited',
  O7_BRIT_LTD = 'Outfit7 Brit Limited',
  O7_NEO_LTD = 'Outfit7 Neo Limited',
  O7_NT_SHG = 'Outfit7 Network Technology Co., Ltd Shanghai Branch',
  O7_LOCAL_COMPANY = 'Outfit7 group local company (depending on location)'
}

export enum EmploymentTypeKey {
  ALL = 'all',
  FULL_TIME = 'fullTime',
  PART_TIME = 'partTime',
  LIMITED_TIME = 'limitedTime',
  INTERNSHIP = 'internship',
  STUDENT = 'student',
  CONTRACTOR = 'contractor',
  FULL_TIME_SERVICE_PROVIDER = 'fullTimeServiceProvider'
}

export enum EmploymentTypeLabel {
  ALL = 'All employment types',
  FULL_TIME = 'Full-time',
  PART_TIME = 'Part-time',
  LIMITED_TIME = 'Limited time',
  INTERNSHIP = 'Internship',
  STUDENT = 'Student',
  CONTRACTOR = 'Contractor',
  FULL_TIME_SERVICE_PROVIDER = 'Full-time service provider'
}

export enum WorkTypeKey {
  ALL = 'all',
  ONSITE = 'onsite',
  REMOTE = 'remote',
  HYBRID = 'hybrid'
}

export enum WorkTypeLabel {
  ALL = 'All work types',
  ONSITE = 'On-site',
  REMOTE = 'Remote',
  HYBRID = 'Hybrid'
}

export const AREAS = [
  { key: AreaKey.ART, label: AreaLabel.ART },
  { key: AreaKey.DATA, label: AreaLabel.DATA },
  { key: AreaKey.LEGAL, label: AreaLabel.LEGAL },
  { key: AreaKey.GAME, label: AreaLabel.GAME },
  { key: AreaKey.MARKETING, label: AreaLabel.MARKETING },
  { key: AreaKey.PEOPLE, label: AreaLabel.PEOPLE },
  { key: AreaKey.PRODUCT, label: AreaLabel.PRODUCT },
  { key: AreaKey.SALES, label: AreaLabel.SALES },
  { key: AreaKey.TECHNOLOGY, label: AreaLabel.TECHNOLOGY },
  { key: AreaKey.VIDEO, label: AreaLabel.VIDEO }
]

export const LOCATIONS = [
  { key: LocationKey.LJUBLJANA, label: LocationLabel.LJUBLJANA },
  { key: LocationKey.LIMASSOL, label: LocationLabel.LIMASSOL },
  { key: LocationKey.SHANGHAI, label: LocationLabel.SHANGHAI }
]

export const EMPLOYMENT_TYPES = [
  { key: EmploymentTypeKey.FULL_TIME, label: EmploymentTypeLabel.FULL_TIME },
  { key: EmploymentTypeKey.LIMITED_TIME, label: EmploymentTypeLabel.LIMITED_TIME },
  { key: EmploymentTypeKey.INTERNSHIP, label: EmploymentTypeLabel.INTERNSHIP },
  { key: EmploymentTypeKey.STUDENT, label: EmploymentTypeLabel.STUDENT }
]

export const WORK_TYPES = [
  { key: WorkTypeKey.ONSITE, label: WorkTypeLabel.ONSITE },
  { key: WorkTypeKey.REMOTE, label: WorkTypeLabel.REMOTE },
  { key: WorkTypeKey.HYBRID, label: WorkTypeLabel.HYBRID }
]
